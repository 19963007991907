import Navbar from "../assets_components/navbar"
import notFound from "../assets/not_found.png"
import { Link } from "react-router-dom";

export default function NotFound(){
  return (
    <>
      <Navbar/>
      <div className="hidden lg:block md:block container">
        <div className="grid grid-cols-1 lg:grid-cols-2 p-2 mt-24 justify-between">
          <div className="not_found_margin">
            <div className="not_found_heading">
              Ooops!!<br></br>Page not found
            </div>
            <div className="not_found_p">
              This page is dosen’t exist or was removed!
              We suggest you go back home
            </div>
            <Link to={'/'} spy={true} smooth={true} className="not_found_button" >
              <h1 className="not_found_text">Go back to homepage</h1>
            </Link>
          </div>
          <div className="not_found_image">
            <img src={notFound}/>
          </div>
        </div>
      </div>
    </>
  );
}